import React from "react";
import LoaderIcon from "../../Assets/Loader/loader.gif";
import './Loader.css'

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderIcon} alt="" />
    </div>
  );
};

export default Loader;
