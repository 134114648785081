import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./Modal.css";
import OtpModal from "../ModalContent/OtpModal";
import OtpSuccess from "../ModalContent/OtpSuccess";

const ModalContainer = (props) => {
  const { index } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: 0,
    transform: "translate(-50%, -50%)",
    width: "325px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: 4.5,
    height: "auto",
    overflowY: "auto",
    border: "none",
    outline: "none",
  };

  const componentMap = {
    0: <OtpModal close={props.closeModal} />,
    1: <OtpSuccess close={props.closeModal} />,
  };

  return (
    <Modal
      open={props.Isopen}
      onClose={props.closeModal}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      className="modal-container"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      style={{ overflow: "scroll" }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{componentMap[index]}</Box>
    </Modal>
  );
};

export default ModalContainer;
