import React, { useEffect, useRef, useState } from "react";
import ResultBg from "../../Assets/Login/result-bg.png";
import "./Survey.css";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Rating from "@mui/material/Rating";
import { Button } from "@mui/material";
import MetaDataTitle from "../../Components/MetaData/MetaDataTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  get_survey_Image_Actions,
  get_survey_Price_range_Actions,
  get_user_details_Actions,
  save_Survey_Rating_price_Actions,
  // save_Survey_Rating_price_Actions,
} from "../../Actions/SurveyActions";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../Components/Loader/Loader";
import { DEFAULT_IMG } from "../../Api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAlert } from "react-alert";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CustomLoader from "../../Components/Loader/CustomLoader";
import { useNavigate } from "react-router-dom";

const Survey = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();

  let user_info_storage = secureLocalStorage.getItem("SH@#USXER");
  let token = user_info_storage && user_info_storage?.token;

  // get_user_details_state
  const { user_details, loading } = useSelector((state) => state.userDetails);

  // get_image_list_state
  const { survey_image, loading: image_loading } = useSelector(
    (state) => state.surveyImage
  );

  // survey_price_range_state
  const { survey_price_range, loading: price_range_loading } = useSelector(
    (state) => state.surveyPriceRange
  );

  // save_survey_state
  const { save_survey, loading: saveLoading } = useSelector(
    (state) => state.saveSurvey
  );

  const [rating, setRating] = useState(0);
  const [isIndex, setIsIndex] = useState();

  const handleActiveSelection = (index) => {
    setIsIndex(index);
  };

  useEffect(() => {
    if (rating === null) {
      setRating(0);
    }
  }, [rating]);

  useEffect(() => {
    dispatch(get_user_details_Actions("survey_user_detail", token, 1));
    dispatch(get_survey_Image_Actions("survey_detail", 1));
    dispatch(get_survey_Price_range_Actions("survey_price_range"));
  }, [dispatch, token]);

  const [filteredData, setFilteredData] = useState(null);

  const [activeId, setActiveId] = useState();

  const sliderRef = useRef(null);

  const handleNextImage = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Move to the next slide
    }
  };

  const PrevArrow = ({ onClick }) => (
    <Button className="custom-arrow prev" onClick={onClick}>
      <ArrowBackIosIcon />
    </Button>
  );

  const NextArrow = ({ onClick }) => (
    <Button className="custom-arrow next" onClick={onClick}>
      <ArrowForwardIosIcon />
    </Button>
  );

  const [activeIndex, setActiveIndex] = useState(1);

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    swipe: true, // Enable swiping
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    ref: sliderRef,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (index) => {
      setActiveIndex(index + 1);
      setActiveId();
      setRating(0);
      setIsIndex();
      setActiveId(survey_image?.data?.surveydetail[index]?.survey_design_id);
      const filteredObject = dataArray.find(
        (data) =>
          data.survey_design_id ===
          survey_image?.data?.surveydetail[index]?.survey_design_id
      );
      setFilteredData(filteredObject);
    },
  };

  useEffect(() => {
    if (Array.isArray(survey_image?.data?.surveydetail)) {
      setActiveId(survey_image?.data?.surveydetail[0].survey_design_id);
    }
  }, [survey_image?.data?.surveydetail]);

  const [dataArray, setDataArray] = useState([]);

  const [ishit, setIshit] = useState(false);

  const addObjectToArray = (id, rating, index) => {
    if (survey_image?.data?.surveydetail?.length === dataArray?.length) {
      setIshit(true);
      dispatch(
        save_Survey_Rating_price_Actions(
          "survey_save_rating_and_price",
          token,
          1,
          dataArray
        )
      );
      return;
    }
    if (
      id === undefined ||
      rating === undefined ||
      rating === 0 ||
      index === undefined
    ) {
      alert.error(
        id === undefined
          ? "Something Went Wrong While Gettting Image Id"
          : rating === undefined || rating === 0
          ? "Please Select Rating"
          : index === undefined
          ? "Please Select Price Range"
          : null
      );
      return;
    }
    const existingIndex = dataArray.findIndex((data) => data.activeId === id);

    if (existingIndex !== -1) {
      const existingData = dataArray[existingIndex];
      const updatedData = { ...existingData, rating, isIndex };
      const updatedArray = [...dataArray];
      updatedArray[existingIndex] = updatedData;
      setDataArray(updatedArray);
      alert.success("Rating Updated Successfully.");
    } else {
      // If activeId is not present in dataArray, add a new entry
      const newDataArray = [
        ...dataArray,
        { survey_design_id: id, rating, priceid: isIndex },
      ];
      setDataArray(newDataArray);
      alert.success("Rated Successfully.");
    }
    handleNextImage();
  };

  useEffect(() => {
    if (filteredData !== undefined && filteredData?.priceid) {
      setIsIndex(filteredData !== undefined && filteredData?.priceid);
    }
  }, [filteredData]);

  useEffect(() => {
    if (save_survey && save_survey?.status === 1 && ishit) {
      alert.success(save_survey && save_survey?.msg);
      setIshit(false);
      navigate("/result");
    } else if (save_survey && save_survey?.status === 0 && ishit) {
      alert.error(save_survey && save_survey?.msg);
      setIshit(false);
    }
  }, [save_survey, alert, ishit, navigate]);

  return (
    <>
      <MetaDataTitle
        title={
          "Survey Form - " +
          `${loading ? "Loading..." : user_details && user_details?.data?.name}`
        }
      />
      {loading || image_loading || price_range_loading ? (
        <Loader />
      ) : (
        <div className="all-screen-container">
          <div
            className="result-header-container"
            style={{
              background: "linear-gradient(135deg, #3F60ED 0%, #AC7AF3 100%)",
            }}
          >
            <div className="result-bg">
              <img src={ResultBg} alt="" />
              <div className="overlay-icon">
                <img
                  onError={(e) => (e.target.src = DEFAULT_IMG)}
                  src={user_details && user_details?.data?.image}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="survey-text-container">
            <p>Welcome To</p>
            <h3>{user_details && user_details?.data?.name}</h3>
            <p>
              You have bin chosen for this survey because you are part of survey
              Trend Track group!
            </p>
          </div>

          <div className="product-container">
            <Slider {...settings}>
              {survey_image &&
                Array.isArray(survey_image?.data?.surveydetail) &&
                survey_image?.data?.surveydetail?.map((slideImage) => (
                  <div key={slideImage.survey_design_id}>
                    <img
                      src={slideImage.image}
                      alt={`slide-${slideImage.survey_design_id}`}
                    />
                  </div>
                ))}
            </Slider>
          </div>

          <div className="rating-selection-container">
            <h3>
              {activeIndex}/{survey_image?.data?.surveydetail?.length}
            </h3>
            <ProgressBar
              totalRated={survey_image?.data?.surveydetail?.length}
              rated={dataArray && dataArray?.length}
            />
            <h4>On a scale of 1 to 5, how much do you like this T-shirts?</h4>
            <Rating
              size={"large"}
              style={{ fontSize: "2.8rem" }}
              value={
                rating
                  ? rating
                  : filteredData !== undefined && filteredData?.rating
                  ? filteredData?.rating
                  : rating
              }
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            <h4 style={{ marginTop: "10px" }}>
              What price range are you looking for in this T-shirt?
            </h4>
            <div className="que-row">
              {survey_price_range &&
                Array.isArray(survey_price_range?.data?.pricerange) &&
                survey_price_range?.data?.pricerange?.map((i, index) => (
                  <Button
                    className={`${
                      isIndex === i.priceid
                        ? "active-select"
                        : "no-active-select"
                    }`}
                    onClick={() => handleActiveSelection(i.priceid)}
                    key={index}
                  >
                    <p
                      className={`${
                        isIndex === i.priceid ? "active-ptag" : "noactive-ptag"
                      }`}
                    >
                      {i.pricetext}
                    </p>
                  </Button>
                ))}
              <div className="text-center">
                <Button
                  className="submit-otp"
                  onClick={() => addObjectToArray(activeId, rating, isIndex)}
                >
                  {saveLoading ? (
                    <CustomLoader />
                  ) : (
                    <>
                      <span>
                        {survey_image?.data?.surveydetail?.length ===
                        dataArray?.length
                          ? "End Survey"
                          : "Submit"}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"
                          fill="white"
                        />
                      </svg>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Survey;
