import { Button } from "@mui/material";
import React from "react";
import successIcon from "../../Assets/Login/success.png";
import "./otpModal.css";
import { useNavigate } from "react-router-dom";

const OtpSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="success-container">
        <img src={successIcon} alt="" />
        <p>
          The survey has already been completed. Please click the button to view
          the results.
        </p>
        <Button onClick={() => navigate("/survey")}>Check Result</Button>
      </div>
    </>
  );
};

export default OtpSuccess;
