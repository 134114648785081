// Get User Details constants
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

// Get Survey Name constants
export const GET_SURVEY_NAME_REQUEST = "GET_SURVEY_NAME_REQUEST";
export const GET_SURVEY_NAME_SUCCESS = "GET_SURVEY_NAME_SUCCESS";
export const GET_SURVEY_NAME_FAILURE = "GET_SURVEY_NAME_FAILURE";

// Get Survey Image constants
export const GET_SURVEY_IMAGE_REQUEST = "GET_SURVEY_IMAGE_REQUEST";
export const GET_SURVEY_IMAGE_SUCCESS = "GET_SURVEY_IMAGE_SUCCESS";
export const GET_SURVEY_IMAGE_FAILURE = "GET_SURVEY_IMAGE_FAILURE";

// Get Survey price range constants
export const GET_SURVEY_PRICE_RANGE_REQUEST = "GET_SURVEY_PRICE_RANGE_REQUEST";
export const GET_SURVEY_PRICE_RANGE_SUCCESS = "GET_SURVEY_PRICE_RANGE_SUCCESS";
export const GET_SURVEY_PRICE_RANGE_FAILURE = "GET_SURVEY_PRICE_RANGE_FAILURE";

// Get Rating Pie chart constants
export const GET_RATING_PIE_CHART_REQUEST = "GET_RATING_PIE_CHART_REQUEST";
export const GET_RATING_PIE_CHART_SUCCESS = "GET_RATING_PIE_CHART_SUCCESS";
export const GET_RATING_PIE_CHART_FAILURE = "GET_RATING_PIE_CHART_FAILURE";

// Get price Pie chart constants
export const GET_PRICE_PIE_CHART_REQUEST = "GET_PRICE_PIE_CHART_REQUEST";
export const GET_PRICE_PIE_CHART_SUCCESS = "GET_PRICE_PIE_CHART_SUCCESS";
export const GET_PRICE_PIE_CHART_FAILURE = "GET_PRICE_PIE_CHART_FAILURE";

// Save Survey Rating and Price constants
export const SAVE_SURVEY_RATING_PRICE_REQUEST = "SAVE_SURVEY_RATING_PRICE_REQUEST";
export const SAVE_SURVEY_RATING_PRICE_SUCCESS = "SAVE_SURVEY_RATING_PRICE_SUCCESS";
export const SAVE_SURVEY_RATING_PRICE_FAILURE = "SAVE_SURVEY_RATING_PRICE_FAILURE";


// Get Summary constants
export const GET_SUMMARY_REQUEST = "GET_SUMMARY_REQUEST";
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAILURE = "GET_SUMMARY_FAILURE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
