import axios from "axios";
import { BASEURL } from "../Api";
import {
  GET_PRICE_PIE_CHART_FAILURE,
  GET_PRICE_PIE_CHART_REQUEST,
  GET_PRICE_PIE_CHART_SUCCESS,
  GET_RATING_PIE_CHART_FAILURE,
  GET_RATING_PIE_CHART_REQUEST,
  GET_RATING_PIE_CHART_SUCCESS,
  GET_SUMMARY_FAILURE,
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  GET_SURVEY_IMAGE_FAILURE,
  GET_SURVEY_IMAGE_REQUEST,
  GET_SURVEY_IMAGE_SUCCESS,
  GET_SURVEY_NAME_FAILURE,
  GET_SURVEY_NAME_REQUEST,
  GET_SURVEY_NAME_SUCCESS,
  GET_SURVEY_PRICE_RANGE_FAILURE,
  GET_SURVEY_PRICE_RANGE_REQUEST,
  GET_SURVEY_PRICE_RANGE_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  SAVE_SURVEY_RATING_PRICE_FAILURE,
  SAVE_SURVEY_RATING_PRICE_REQUEST,
  SAVE_SURVEY_RATING_PRICE_SUCCESS,
} from "../Constants/SurveyConstants";

// get user details actions
export const get_user_details_Actions =
  (service_name, token, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_USER_DETAILS_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        token,
        surveyid,
      });
      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };

// get survey name actions
export const get_survey_name_Actions =
  (service_name, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SURVEY_NAME_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        surveyid,
      });
      dispatch({
        type: GET_SURVEY_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEY_NAME_FAILURE,
        payload: error.message,
      });
    }
  };

// get survey Image actions
export const get_survey_Image_Actions =
  (service_name, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SURVEY_IMAGE_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        surveyid,
      });
      dispatch({
        type: GET_SURVEY_IMAGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEY_IMAGE_FAILURE,
        payload: error.message,
      });
    }
  };

// get survey Price Range actions
export const get_survey_Price_range_Actions =
  (service_name) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SURVEY_PRICE_RANGE_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
      });
      dispatch({
        type: GET_SURVEY_PRICE_RANGE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SURVEY_PRICE_RANGE_FAILURE,
        payload: error.message,
      });
    }
  };

// get Rating Pie Chart actions
export const get_Rating_Pie_chart_Actions =
  (service_name, token, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_RATING_PIE_CHART_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        token,
        surveyid,
      });
      dispatch({
        type: GET_RATING_PIE_CHART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_RATING_PIE_CHART_FAILURE,
        payload: error.message,
      });
    }
  };

// get Price Pie Chart actions
export const get_Price_Pie_chart_Actions =
  (service_name, token, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PRICE_PIE_CHART_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        token,
        surveyid,
      });
      dispatch({
        type: GET_PRICE_PIE_CHART_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PRICE_PIE_CHART_FAILURE,
        payload: error.message,
      });
    }
  };

// Save Survey Rating actions
export const save_Survey_Rating_price_Actions =
  (service_name, token, surveyid, data) => async (dispatch) => {
    try {
      dispatch({
        type: SAVE_SURVEY_RATING_PRICE_REQUEST,
      });

      const { data: datas } = await axios.post(`${BASEURL}`, {
        service_name,
        token,
        surveyid,
        data,
      });
      dispatch({
        type: SAVE_SURVEY_RATING_PRICE_SUCCESS,
        payload: datas,
      });
    } catch (error) {
      dispatch({
        type: SAVE_SURVEY_RATING_PRICE_FAILURE,
        payload: error.message,
      });
    }
  };

// get Summary Data actions
export const get_summary_data_Actions =
  (service_name, token, surveyid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SUMMARY_REQUEST,
      });

      const { data } = await axios.post(`${BASEURL}`, {
        service_name,
        token,
        surveyid,
      });
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_SUMMARY_FAILURE,
        payload: error.message,
      });
    }
  };
