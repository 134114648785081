import { combineReducers } from "redux";
import {
  get_Summary_Reducers,
  get_pricePie_chart_Reducers,
  get_ratingPie_chart_Reducers,
  get_survey_Image_Reducers,
  get_survey_Price_range_Reducers,
  get_survey_name_Reducers,
  get_user_details_Reducers,
  save_rating_price_Reducers,
} from "./Reducers/SurveyReducers";

const RootReducer = combineReducers({
  userDetails: get_user_details_Reducers,
  surveyName: get_survey_name_Reducers,
  surveyImage: get_survey_Image_Reducers,
  surveyPriceRange: get_survey_Price_range_Reducers,
  ratingPieChart: get_ratingPie_chart_Reducers,
  pricePieChart: get_pricePie_chart_Reducers,
  saveSurvey: save_rating_price_Reducers,
  get_Summary: get_Summary_Reducers,
});

export default RootReducer;
