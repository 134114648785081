import { Button } from "@mui/material";
import React, { useCallback } from "react";
import "./otpModal.css";

const OtpModal = (props) => {
  const { close } = props;
  const closeModal = useCallback(() => {
    close(null);
  }, [close]);
  return (
    <>
      <div className="error-container">
        <h4>Wrong OTP</h4>
        <p>The OTP you entered is invalid.</p>
        <p>Please enter the correct OTP</p>
        <Button onClick={closeModal}>Try Again</Button>
      </div>
    </>
  );
};

export default OtpModal;
