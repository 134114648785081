import React from "react";

const ProgressBar = (props) => {
  let progress = (props.rated / props.totalRated) * 100;
  return (
    <div className="progress-bar">
      <div
        className="progress"
        style={{
          width: `${progress}%`,
          background: "linear-gradient(90deg, #F00 0%, #514EFF 44.58%)",
          height: "6px",
          borderRadius: "3px",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
