import React, { useEffect } from "react";
import "./Result.css";
import ResultBg from "../../Assets/Login/result-bg.png";
import Summary from "../../Components/Summary/Summary";
import DoughnutChart from "../../Components/DoughnutChart/DoughnutChart";
import MetaDataTitle from "../../Components/MetaData/MetaDataTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  get_Price_Pie_chart_Actions,
  get_Rating_Pie_chart_Actions,
  get_summary_data_Actions,
} from "../../Actions/SurveyActions";
import secureLocalStorage from "react-secure-storage";
import Loader from "../../Components/Loader/Loader";
import PriceDoughnutChart from "../../Components/DoughnutChart/PriceDoughnutChart";

const Result = () => {
  let user_info_storage = secureLocalStorage.getItem("SH@#USXER");
  let token = user_info_storage && user_info_storage?.token;

  const dispatch = useDispatch();

  // get_rating_pie_chart_state
  const { rating_pie_chart, loading: rating_loading } = useSelector(
    (state) => state.ratingPieChart
  );

  // get_price_pie_chart_state
  const { price_pie_chart, loading: price_loading } = useSelector(
    (state) => state.pricePieChart
  );

  // get_summary_data_state
  const { summary_data, loading } = useSelector((state) => state.get_Summary);

  useEffect(() => {
    dispatch(get_Rating_Pie_chart_Actions("survey_rating_piechart", token, 1));
    dispatch(get_Price_Pie_chart_Actions("survey_price_piechart", token, 1));
    dispatch(
      get_summary_data_Actions("survey_compare_own_other_user_rating", token, 1)
    );
  }, [dispatch, token]);


  return (
    <>
      <MetaDataTitle title={`Survey Form - Thank You`} />
      {price_loading || rating_loading || loading ? (
        <Loader />
      ) : (
        <div className="all-screen-container">
          <div className="result-header-container">
            <div className="result-bg">
              <img src={ResultBg} alt="" />
              <div className="overlay-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="132"
                  height="130"
                  viewBox="0 0 132 130"
                  fill="none"
                >
                  <path
                    d="M65.8166 130C102.166 130 131.633 100.899 131.633 65C131.633 29.1015 102.166 0 65.8166 0C29.4671 0 0 29.1015 0 65C0 100.899 29.4671 130 65.8166 130Z"
                    fill="url(#paint0_linear_2_793)"
                  />
                  <path
                    d="M65.8129 119.664C96.3796 119.664 121.159 95.1918 121.159 65.0044C121.159 34.817 96.3796 10.3453 65.8129 10.3453C35.2463 10.3453 10.4672 34.817 10.4672 65.0044C10.4672 95.1918 35.2463 119.664 65.8129 119.664Z"
                    fill="url(#paint1_linear_2_793)"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M41.4136 37.0209V94.3309H52.4997V70.3572H58.0833V59.9825H52.4997V37.0209H41.4136ZM73.5483 70.3572H79.1268V94.336H90.213V37.0209H79.1268V59.9774H73.5483V70.3572Z"
                    fill="#FFC431"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M65.8145 35.6635C62.767 35.6635 60.0588 37.9571 60.1448 41.7001C60.0588 45.2702 62.6 47.7367 65.6474 47.7367C69.034 47.7367 71.5702 45.2702 71.4892 41.7001C71.4841 37.9571 69.034 35.6635 65.8145 35.6635Z"
                    fill="#FFC431"
                  />
                  <path
                    d="M71.3588 52.7555H60.2726V94.3356H71.3588V52.7555Z"
                    fill="#FFC431"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2_793"
                      x1="100.558"
                      y1="-52.5648"
                      x2="55.2591"
                      y2="104.648"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFE14D" />
                      <stop offset="1" stop-color="#F7A523" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2_793"
                      x1="95.0268"
                      y1="-33.8483"
                      x2="56.9377"
                      y2="98.3418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FAAD1E" />
                      <stop offset="0.9959" stop-color="#F2830B" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div className="result-text-container">
            <h4>Thank You</h4>
            <p>
              for complete this survey. As a token for apparition you are award
              2 rewards points.
            </p>
          </div>

          <div className="rating-box">
            <h3>RATING</h3>
            {rating_pie_chart &&
            Array.isArray(rating_pie_chart?.data?.ratingdetailpercent) ? (
              <DoughnutChart
                data_list={
                  rating_pie_chart &&
                  Array.isArray(rating_pie_chart?.data?.ratingdetailpercent)
                    ? rating_pie_chart?.data?.ratingdetailpercent
                    : []
                }
              />
            ) : null}
          </div>

          <div className="rating-box">
            <h3>PRICE</h3>
            {price_pie_chart &&
            Array.isArray(price_pie_chart?.data?.pricedetailpercent) ? (
              <PriceDoughnutChart
                data_list={
                  price_pie_chart &&
                  Array.isArray(price_pie_chart?.data?.pricedetailpercent)
                    ? price_pie_chart?.data?.pricedetailpercent
                    : []
                }
              />
            ) : null}
          </div>

          <div className="summary-container">
            <h4>Summary</h4>
            {summary_data &&
              Array.isArray(summary_data?.data?.ratecomparison) &&
              summary_data?.data?.ratecomparison?.map((i, index) => (
                <Summary
                  key={index}
                  number={i.slno}
                  img={i.thumbnail}
                  other_rating={i.other_rating}
                  own_rating={i.own_rating}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Result;
