import {
  CLEAR_ERRORS,
  GET_PRICE_PIE_CHART_FAILURE,
  GET_PRICE_PIE_CHART_REQUEST,
  GET_PRICE_PIE_CHART_SUCCESS,
  GET_RATING_PIE_CHART_FAILURE,
  GET_RATING_PIE_CHART_REQUEST,
  GET_RATING_PIE_CHART_SUCCESS,
  GET_SUMMARY_FAILURE,
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  GET_SURVEY_IMAGE_FAILURE,
  GET_SURVEY_IMAGE_REQUEST,
  GET_SURVEY_IMAGE_SUCCESS,
  GET_SURVEY_NAME_FAILURE,
  GET_SURVEY_NAME_REQUEST,
  GET_SURVEY_NAME_SUCCESS,
  GET_SURVEY_PRICE_RANGE_FAILURE,
  GET_SURVEY_PRICE_RANGE_REQUEST,
  GET_SURVEY_PRICE_RANGE_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  SAVE_SURVEY_RATING_PRICE_FAILURE,
  SAVE_SURVEY_RATING_PRICE_REQUEST,
  SAVE_SURVEY_RATING_PRICE_SUCCESS,
} from "../Constants/SurveyConstants";

// get user details Reducers
export const get_user_details_Reducers = (
  state = { user_details: {} },
  action
) => {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user_details: action.payload,
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Survey Name Reducers
export const get_survey_name_Reducers = (
  state = { survey_name: {} },
  action
) => {
  switch (action.type) {
    case GET_SURVEY_NAME_REQUEST:
      return {
        loading: true,
      };
    case GET_SURVEY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        survey_name: action.payload,
      };
    case GET_SURVEY_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Survey Image Reducers
export const get_survey_Image_Reducers = (
  state = { survey_image: {} },
  action
) => {
  switch (action.type) {
    case GET_SURVEY_IMAGE_REQUEST:
      return {
        loading: true,
      };
    case GET_SURVEY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        survey_image: action.payload,
      };
    case GET_SURVEY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Survey Price Range Reducers
export const get_survey_Price_range_Reducers = (
  state = { survey_price_range: {} },
  action
) => {
  switch (action.type) {
    case GET_SURVEY_PRICE_RANGE_REQUEST:
      return {
        loading: true,
      };
    case GET_SURVEY_PRICE_RANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        survey_price_range: action.payload,
      };
    case GET_SURVEY_PRICE_RANGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Rating Pie chart Reducers
export const get_ratingPie_chart_Reducers = (
  state = { rating_pie_chart: {} },
  action
) => {
  switch (action.type) {
    case GET_RATING_PIE_CHART_REQUEST:
      return {
        loading: true,
      };
    case GET_RATING_PIE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        rating_pie_chart: action.payload,
      };
    case GET_RATING_PIE_CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Price Pie chart Reducers
export const get_pricePie_chart_Reducers = (
  state = { price_pie_chart: {} },
  action
) => {
  switch (action.type) {
    case GET_PRICE_PIE_CHART_REQUEST:
      return {
        loading: true,
      };
    case GET_PRICE_PIE_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        price_pie_chart: action.payload,
      };
    case GET_PRICE_PIE_CHART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// save Rating and price Reducers
export const save_rating_price_Reducers = (
  state = { save_survey: {} },
  action
) => {
  switch (action.type) {
    case SAVE_SURVEY_RATING_PRICE_REQUEST:
      return {
        loading: true,
      };
    case SAVE_SURVEY_RATING_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        save_survey: action.payload,
      };
    case SAVE_SURVEY_RATING_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get Summary Reducers
export const get_Summary_Reducers = (state = { summary_data: {} }, action) => {
  switch (action.type) {
    case GET_SUMMARY_REQUEST:
      return {
        loading: true,
      };
    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        summary_data: action.payload,
      };
    case GET_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
