import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const PriceDoughnutChart = ({ data_list }) => {
  defaults.font.family = "Poppins_SemiBold";

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const dataProperties = Object.keys(data_list[0]).filter(
    (key) => key !== "price_id" && key !== "price_text"
  );

  const colors = Array.from({ length: dataProperties.length }, getRandomColor);
  colors.sort(() => Math.random() - 0.5);

  const datasets = dataProperties.map((key, index) => {
    const label = key;
    const backgroundColors = data_list.map(() => getRandomColor()); // Generate unique colors for each data point
    return {
      label: label,
      data: data_list.map((data) => parseInt(data[key])),
      backgroundColor: backgroundColors,
    };
  });

  const labels = data_list.map((data) => data.price_text);

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    cutout: "85%", // Adjust the cutout size to create more space for labels
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          padding: 10,
          font: {
            family: "Poppins_SemiBold",
          },
          usePointStyle: true,
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0, // Remove border from the doughnut slices
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;

      // calulating average
      let sum = 0;
      data?.datasets?.forEach((i) => {
        i?.data?.forEach((j) => {
          sum += j;
        });
      });

      const xcoor = chart.getDatasetMeta(0).data[0].x;
      const ycoor = chart.getDatasetMeta(0).data[0].y;
      ctx.save();
      ctx.font = "12px Poppins_Regular";
      ctx.fillStyle = "#9291A5";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`Average range`, xcoor, ycoor + 20);

      ctx.font = "24px Poppins_Bold";
      ctx.fillStyle = "#1E1B39";
      ctx.fillText(`${sum}`, xcoor, ycoor);
    },
  };

  return (
    <Doughnut
      plugins={[textCenter]}
      className="doughnut-chart"
      data={data}
      options={options}
    />
  );
};

export default PriceDoughnutChart;
