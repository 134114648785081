import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Screens/Login/Login";
import Result from "./Screens/Results/Result";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Survey from "./Screens/Survey/Survey";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/result" element={<Result />} />
          <Route path="/survey" element={<Survey />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

// change
