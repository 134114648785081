import React from "react";
import "./Summary.css";

const Summary = ({ number, img, other_rating, own_rating }) => {
  return (
    <div className="grid-summary-container">
      <div className="number-circle">
        <p>{number}</p>
      </div>
      <div>
        <img src={img} alt="" />
      </div>
      <div>
        <p>
          The other user gave a rating of {other_rating}, while you provided a
          rating of <b>{own_rating}</b>.
        </p>
      </div>
    </div>
  );
};

export default Summary;
